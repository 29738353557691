import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import axios from 'utils/axios';
import URL from 'config/url';

const cookies = new Cookies();

export const getAgencies = createAsyncThunk('GET AGENCY', async (data, thunkAPI) => {
  const response = await axios.get(
    `${URL.GET_AGENCY}?page=${data.page}&pageSize=${data.pageSize}&limit=${data.limit}&text=${data.text || ''
    }&startDate=${data.startDate}&endDate=${data.endDate}&planType=${data.planType}&sortOrder=${data.sortOrder
    }&sortField=${data.sortField}&subscriptionDuration=${data.subscriptionDuration}&paymentStatus=${data.paymentStatus
    }&agencyStatus=${data.agencyStatus}&mailgunStatus=${data?.mailgunStatus}&subscriptionStatus=${data?.subscriptionStatus
    }&originCountry=${data?.originCountry}`,
    thunkAPI
  );
  return response.data;
});

export const getAgencyDetails = createAsyncThunk('GET AGENCY DETAILS', async (data, thunkAPI) => {
  const response = await axios.get(`${URL.GET_AGENCY}/${data?.agencyId}`, thunkAPI);
  return response.data;
});

export const deleteAgency = createAsyncThunk('DELETE AGENCY', async (data, thunkAPI) => {
  const response = await axios.delete(`${URL.GET_AGENCY}/${data?.agencyId}`, thunkAPI);
  return response.data;
});

export const getAgencyUsers = createAsyncThunk('GET AGENCY USERS', async (data, thunkAPI) => {
  const response = await axios.get(
    `${URL.AGENCY_USERS}?agencyId=${data?.agencyId}&status=${data?.status}&userType=${data?.userType}&page=${data.page
    }&pageSize=${data.pageSize}&limit=${data.limit}&text=${data.text || ''}&name=${data.name || ''}&email=${data.email && encodeURIComponent(data.email) || ''
    }`,
    thunkAPI
  );
  return response.data;
});

export const updateAgencyPaymentStatus = createAsyncThunk('UPDATE AGENCY PAYMENT STATUS', async (data, thunkAPI) => {
  const response = await axios.patch(URL.AGENCY_PAYMENT_STATUS, data, thunkAPI);
  return response.data;
});

export const updateTicketingPermission = createAsyncThunk('UPDATE TICKETING PERMISSION', async (data, thunkAPI) => {
  const response = await axios.patch(URL.AGENCY_FLIGHT_TICKET_PERMISSION, data, thunkAPI);
  return response.data;
});

export const getAgencySettings = createAsyncThunk('GET AGENCY SETTINGS', async (data, thunkAPI) => {
  const response = await axios.get(`${URL.AGENCY_SETTINGS}/${data?.agencyId}`, thunkAPI);
  return response.data;
});

export const updateAgencyStatus = createAsyncThunk('UPDATE AGENCY STATUS', async (data, thunkAPI) => {
  const response = await axios.patch(URL.AGENCY_STATUS, data, thunkAPI);
  return response.data;
});

export const updateDisallowSameDay = createAsyncThunk(
  'UPDATE AGENCY DIALLOW SAME DAY SETTINGS',
  async (data, thunkAPI) => {
    const response = await axios.patch(URL.AGENCY_SETTINGS, data, thunkAPI);
    return response.data;
  }
);

export const updateApiAccess = createAsyncThunk('UPDATE AGENCY API ACCESS SETTINGS', async (data, thunkAPI) => {
  const response = await axios.patch(URL.AGENCY_SETTINGS, data, thunkAPI);
  return response.data;
});

export const updateManageLocation = createAsyncThunk('UPDATE MANAGE LOCATION SETTINGS', async (data, thunkAPI) => {
  const response = await axios.patch(URL.AGENCY_SETTINGS, data, thunkAPI);
  return response.data;
});

export const updateAgencySettings = createAsyncThunk('UPDATE AGENCY SETTINGS', async (data, thunkAPI) => {
  const response = await axios.patch(URL.AGENCY_SETTINGS, data, thunkAPI);
  return response.data;
});

export const cancelAgencySubscription = createAsyncThunk('CANCEL AGENCY SUBSCRIPTION', async (data, thunkAPI) => {
  const response = await axios.delete(URL.AGENCY_CANCEL_SUBSCRIPTION, data, thunkAPI);
  return response.data;
});

export const cancelTrialSubscription = createAsyncThunk('CANCEL AGENCY TRIAL SUBSCRIPTION', async (data, thunkAPI) => {
  const response = await axios.delete(URL.AGENCY_TRIAL_CANCEL_SUBSCRIPTION, data, thunkAPI);
  return response.data;
});

export const updateAgencyUserStatus = createAsyncThunk('UPDATE AGENCY USER STATUS', async (data, thunkAPI) => {
  const response = await axios.patch(URL.AGENCY_USER_STATUS, data, thunkAPI);
  return response.data;
});

export const resendVerificationEmail = createAsyncThunk('RESEND VERIFICATION EMAIL', async (data, thunkAPI) => {
  const response = await axios.post(URL.AGENCY_USER_RESEND_EMAIL_VERIFICATION, data, thunkAPI);
  return response.data;
});

export const resendVerificationAgencyEmail = createAsyncThunk(
  'RESEND AGENCY VERIFICATION EMAIL',
  async (data, thunkAPI) => {
    const response = await axios.get(`${URL.AGENCY_VERIFY_USER_RESEND_EMAIL}/${data?.agencyId}`, thunkAPI);
    return response.data;
  }
);

export const agencyUserLogin = createAsyncThunk('AGENCY USER LOGIN', async (data, thunkAPI) => {
  const response = await axios.post(URL.AGENCY_USER_LOGIN, data, thunkAPI);
  if (response.data.status) {
    cookies.set('x-access-token', response.data.data.token, {
      domain: response.data.data.topLevelDomain,
      path: '/',
      maxAge: 3600 * 24 * 7,
    });
    window.open(`${window.location.protocol}//${response.data.data.domain}`, '_blank');
  }
  return response.data;
});

export const updateCruiseSetting = createAsyncThunk('ENABLE OR DISABLE CRUISE SERVICE', async (data, thunkAPI) => {
  const response = await axios.post(URL.UPDATE_CRUISE_SETTING, data, thunkAPI);
  return response.data;
});

export const getAgentDetails = createAsyncThunk('GET AGENT DETAILS', async (data, thunkAPI) => {
  const response = await axios.get(
    `${URL.GET_AGENT_DETAILS}?agencyId=${data?.agencyId}&agentId=${data?.agentId}`,
    thunkAPI
  );
  return response.data;
});

export const getAssociatedAgentClients = createAsyncThunk('GET ASSOCIATED AGENT CLIENTS', async (data, thunkAPI) => {
  const response = await axios.get(
    `${URL.GET_ASSOCIATED_AGENTS_CLIENTS}?page=${data.page}&pageSize=${data.pageSize}&limit=${data.limit}&agencyId=${data?.agencyId
    }&agentId=${data?.agentId}&status=${data?.status}&associationStatus=${data?.associationStatus}&name=${data.name || ''
    }&email=${data.email || ''}`,
    thunkAPI
  );
  return response.data;
});

export const updateAgencyPaymentGateway = createAsyncThunk('UPDATE AGENCY PAYMENT GATEWAY', async (data, thunkAPI) => {
  const response = await axios.patch(URL.UPDATE_AGENCY_PAYMENT_GATEWAY, data, thunkAPI);
  return response.data;
});

export const updateBitpayPaymentAllowed = createAsyncThunk('UPDATE BITPAY PAYMENT ALLOWED', async (data, thunkAPI) => {
  const response = await axios.patch(URL.UPDATE_BITPAY_PAYMENT_ALLOWED, data, thunkAPI);
  return response.data;
});

export const updateAgencyHotelVendor = createAsyncThunk('UPDATE AGENCY HOTEL VENDOR', async (data, thunkAPI) => {
  const response = await axios.patch(URL.UPDATE_AGENCY_HOTEL_VENDOR, data, thunkAPI);
  return response.data;
});

export const updateOTPSignupStatus = createAsyncThunk('UPDATE HIDE HEADER STATUS', async (data, thunkAPI) => {
  const response = await axios.patch(URL.UPDATE_AGENCY_OTP_SIGNUP_STATUS, data, thunkAPI);
  return response.data;
});

export const updatHideHeaderStatus = createAsyncThunk('UPDATE OTP SIGNUP STATUS', async (data, thunkAPI) => {
  const response = await axios.patch(URL.UPDATE_AGENCY_HIDE_HEADER_STATUS, data, thunkAPI);
  return response.data;
});

export const updateExranetStatus = createAsyncThunk('UPDATE EXTRANET STATUS', async (data, thunkAPI) => {
  const response = await axios.patch(URL.UPDATE_AGENCY_EXTRANET_STATUS, data, thunkAPI);
  return response.data;
});

export const updateHideDealOrProposalOption = createAsyncThunk('UPDATE HIDE DEAL OR PROPOSAL', async (data, thunkAPI) => {
  const response = await axios.patch(URL.UPDATE_AGENCY_HIDE_DEAL_OR_PROPOSAL_OPTION, data, thunkAPI);
  return response.data;
});

const initialState = {
  isLoading: false,
  isUserLoading: false,
  isPaymentStatusLoading: false,
  isAgencyStatusLoading: false,
  isPaymentGatewayLoading: false,
  isBitpayPaymentLoading: false,
  isSameDayAllowBookingLoading: false,
  isCancelSubscriptionLoading: false,
  agency: null,
  users: null,
  agencySettings: null,
  isUserUpdateLoading: false,
  isApiAccessLoading: false,
  isFlightTicketingLoading: false,
  isUserLimitLoading: false,
  agentDetails: null,
  associatedUsers: null,
  isManageLocationLoading: false,
  isHotelVendorLoading: false,
  OTPSignupEnableLoading: false,
  hideHeaderEnableLoading: false,
  extranetEnabled: false,
  hideDealOrProposalLoading: false,
};

export const agencySlice = createSlice({
  name: 'agency',
  initialState,
  reducers: {},
  extraReducers: {
    [updateCruiseSetting.pending]: (state) => {
      state.isLoading = true;
    },
    [updateCruiseSetting.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.agencySettings = {
        ...state.agencySettings,
        agencySettings: {
          ...state.agencySettings.agencySettings,
          isCruiseEnable: action.payload.data.isEnabled,
        },
      };
      toast.success(action?.payload?.message);
    },
    [updateCruiseSetting.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action?.payload?.message);
    },
    // get agencies
    [getAgencies.pending]: (state) => {
      state.isLoading = true;
      state.agency = null;
    },
    [getAgencies.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.agency = action.payload.data;
    },
    [getAgencies.rejected]: (state, action) => {
      state.isLoading = false;
      state.agency = null;
      toast.error(action?.payload?.message);
    },

    // get agency details
    [getAgencyDetails.pending]: (state) => {
      state.isLoading = true;
      state.agency = null;
    },
    [getAgencyDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.agency = action.payload.data;
    },
    [getAgencyDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.agency = null;
      toast.error(action?.payload?.message);
    },

    // get agency users
    [getAgencyUsers.pending]: (state) => {
      state.isUserLoading = true;
      state.users = null;
    },
    [getAgencyUsers.fulfilled]: (state, action) => {
      state.isUserLoading = false;
      state.users = action.payload.data;
    },
    [getAgencyUsers.rejected]: (state, action) => {
      state.isUserLoading = false;
      state.users = null;
      toast.error(action?.payload?.message);
    },

    // update agency payment status
    [updateAgencyPaymentStatus.pending]: (state) => {
      state.isPaymentStatusLoading = true;
    },
    [updateAgencyPaymentStatus.fulfilled]: (state, action) => {
      const { agencySettings } = state;
      const data = {
        ...agencySettings.agencySettings,
        paymentStatus: action.payload.data.paymentStatus,
      };
      state.agencySettings = {
        agencySettings: {
          ...data,
        },
      };
      state.isPaymentStatusLoading = false;
    },
    [updateAgencyPaymentStatus.rejected]: (state, action) => {
      state.isPaymentStatusLoading = false;
      toast.error(action?.payload?.message);
    },

    // get agency settings
    [getAgencySettings.pending]: (state) => {
      state.isPaymentStatusLoading = true;
      state.isAgencyStatusLoading = true;
      state.isPaymentGatewayLoading = true;
      state.isBitpayPaymentLoading = true;
      state.isManageLocationLoading = true;
      state.isSameDayAllowBookingLoading = true;
      state.isUserLimitLoading = true;
      state.agencySettings = null;
      state.isHotelVendorLoading = true;
    },
    [getAgencySettings.fulfilled]: (state, action) => {
      state.isPaymentStatusLoading = false;
      state.isAgencyStatusLoading = false;
      state.isPaymentGatewayLoading = false;
      state.isBitpayPaymentLoading = false;
      state.isManageLocationLoading = false;
      state.isSameDayAllowBookingLoading = false;
      state.isUserLimitLoading = false;
      state.agencySettings = action.payload.data;
      state.isHotelVendorLoading = false;
    },
    [getAgencySettings.rejected]: (state, action) => {
      state.isPaymentStatusLoading = false;
      state.isAgencyStatusLoading = false;
      state.isPaymentGatewayLoading = false;
      state.isBitpayPaymentLoading = false;
      state.isManageLocationLoading = false;
      state.isSameDayAllowBookingLoading = false;
      state.isUserLimitLoading = false;
      state.agencySettings = null;
      state.isHotelVendorLoading = false;
      toast.error(action?.payload?.message);
    },

    // update agency status
    [updateAgencyStatus.pending]: (state) => {
      state.isAgencyStatusLoading = true;
    },
    [updateAgencyStatus.fulfilled]: (state, action) => {
      const { agencySettings } = state;
      const data = {
        ...agencySettings.agencySettings,
        status: action.payload.data.status,
      };
      state.agencySettings = {
        agencySettings: {
          ...data,
        },
      };
      state.isAgencyStatusLoading = false;
    },
    [updateAgencyStatus.rejected]: (state, action) => {
      state.isAgencyStatusLoading = false;
      toast.error(action?.payload?.message);
    },

    // update agency disallow same day
    [updateDisallowSameDay.pending]: (state) => {
      state.isSameDayAllowBookingLoading = true;
    },
    [updateDisallowSameDay.fulfilled]: (state, action) => {
      const { agencySettings } = state;
      const data = {
        ...agencySettings.agencySettings,
        disallowSameDayBooking: action.payload.data.disallowSameDayBooking,
      };
      state.agencySettings = {
        agencySettings: {
          ...data,
        },
      };
      state.isSameDayAllowBookingLoading = false;
    },
    [updateDisallowSameDay.rejected]: (state, action) => {
      state.isSameDayAllowBookingLoading = false;
      toast.error(action?.payload?.message);
    },

    // update agency disallow same day
    [updateApiAccess.pending]: (state) => {
      state.isApiAccessLoading = true;
    },
    [updateApiAccess.fulfilled]: (state, action) => {
      const { agencySettings } = state;
      const data = {
        ...agencySettings.agencySettings,
        apiAccess: action.payload.data.apiAccess,
      };
      state.agencySettings = {
        agencySettings: {
          ...data,
        },
      };
      state.isApiAccessLoading = false;
    },
    [updateApiAccess.rejected]: (state) => {
      state.isApiAccessLoading = false;
    },

    [updateAgencySettings.pending]: (state) => {
      state.isUserLimitLoading = true;
    },
    [updateAgencySettings.fulfilled]: (state, action) => {
      state.isUserLimitLoading = false;
      const { agencySettings } = state;
      const data = {
        ...agencySettings.agencySettings,
        limit: action.payload.data.limit,
      };
      state.agencySettings = {
        agencySettings: {
          ...data,
        },
      };
    },
    [updateAgencySettings.rejected]: (state) => {
      state.isUserLimitLoading = false;
    },

    // cancel agency subscription
    [cancelAgencySubscription.pending]: (state) => {
      state.isCancelSubscriptionLoading = true;
    },
    [cancelAgencySubscription.fulfilled]: (state, action) => {
      const { agencySettings } = state;
      const data = {
        ...agencySettings.agencySettings,
        subscriptionStatus: action.payload.data.subscriptionStatus,
      };
      state.agencySettings = {
        agencySettings: {
          ...data,
        },
      };
      state.isCancelSubscriptionLoading = false;
      toast.success(action?.payload?.message);
    },
    [cancelAgencySubscription.rejected]: (state, action) => {
      state.isCancelSubscriptionLoading = false;
      toast.error(action?.payload?.message);
    },

    // cancel agency trial subscription
    [cancelTrialSubscription.pending]: (state) => {
      state.isCancelSubscriptionLoading = true;
    },
    [cancelTrialSubscription.fulfilled]: (state, action) => {
      const { agencySettings } = state;
      const data = {
        ...agencySettings.agencySettings,
        subscriptionStatus: action.payload.data.subscriptionStatus,
      };
      state.agencySettings = {
        agencySettings: {
          ...data,
        },
      };
      state.isCancelSubscriptionLoading = false;
    },
    [cancelTrialSubscription.rejected]: (state, action) => {
      state.isCancelSubscriptionLoading = false;
      toast.error(action?.payload?.message);
    },

    // update agency user status
    [updateAgencyUserStatus.pending]: (state) => {
      state.isUserUpdateLoading = true;
    },
    [updateAgencyUserStatus.fulfilled]: (state, action) => {
      const { users } = state;
      const data = users.users.map((el) => {
        if (el?._id === action.payload.data.user?._id) {
          return {
            ...el,
            ...action.payload.data.user,
          };
        }
        return el;
      });
      state.users = { users: data, count: users?.count };
      state.isUserUpdateLoading = false;
    },
    [updateAgencyUserStatus.rejected]: (state, action) => {
      state.isUserUpdateLoading = false;
      toast.error(action?.payload?.message);
    },

    // resend verification email
    [resendVerificationEmail.pending]: (state) => {
      state.isUserUpdateLoading = true;
    },
    [resendVerificationEmail.fulfilled]: (state, action) => {
      state.isUserUpdateLoading = false;
      toast.success(action.payload.message);
    },
    [resendVerificationEmail.rejected]: (state, action) => {
      state.isUserUpdateLoading = false;
      toast.error(action?.payload?.message);
    },

    // agency user Login
    [agencyUserLogin.pending]: (state) => {
      state.isUserUpdateLoading = true;
    },
    [agencyUserLogin.fulfilled]: (state, action) => {
      state.isUserUpdateLoading = false;
      toast.success(action.payload.message);
    },
    [agencyUserLogin.rejected]: (state, action) => {
      state.isUserUpdateLoading = false;
      toast.error(action?.payload?.message);
    },

    // deleteAgency
    [deleteAgency.pending]: (state) => {
      state.isLoading = true;
    },
    [deleteAgency.fulfilled]: (state, action) => {
      state.isLoading = false;
      const { agency } = state;
      const data = agency.agencies.filter((el) => el.id !== action.payload.data.id);
      state.agency = { agencies: data, agencyCount: agency?.agencyCount - 1 };
      toast.success(action.payload.message);
    },
    [deleteAgency.rejected]: (state, action) => {
      state.isLoading = false;
      toast.error(action?.payload?.message);
    },

    // update flight ticket permission
    [updateTicketingPermission.pending]: (state) => {
      state.isFlightTicketingLoading = true;
    },
    [updateTicketingPermission.fulfilled]: (state, action) => {
      const { agencySettings } = state;
      const data = {
        ...agencySettings.agencySettings,
        isFlightTicketingAllowed: action.payload.data.isFlightTicketingAllowed,
      };
      state.agencySettings = {
        agencySettings: {
          ...data,
        },
      };
      state.isFlightTicketingLoading = false;
    },
    [updateTicketingPermission.rejected]: (state, action) => {
      state.isFlightTicketingLoading = false;
      toast.error(action?.payload?.message);
    },

    // resend verify agency email
    [resendVerificationAgencyEmail.fulfilled]: (state, action) => {
      toast.success(action.payload.message);
    },

    [resendVerificationAgencyEmail.rejected]: (state, action) => {
      toast.error(action?.payload?.message);
    },

    // get agent details
    [getAgentDetails.pending]: (state) => {
      state.isLoading = true;
      state.agentDetails = null;
    },
    [getAgentDetails.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.agentDetails = action.payload.data;
    },
    [getAgentDetails.rejected]: (state, action) => {
      state.isLoading = false;
      state.agentDetails = null;
      toast.error(action?.payload?.message);
    },

    // get associated clients
    [getAssociatedAgentClients.pending]: (state) => {
      state.isLoading = true;
      state.associatedClients = null;
    },
    [getAssociatedAgentClients.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.associatedClients = action.payload.data;
    },
    [getAssociatedAgentClients.rejected]: (state, action) => {
      state.isLoading = false;
      state.associatedClients = null;
      toast.error(action?.payload?.message);
    },

    [updateAgencyPaymentGateway.pending]: (state) => {
      state.isPaymentGatewayLoading = true;
    },
    [updateAgencyPaymentGateway.fulfilled]: (state, action) => {
      const { agencySettings } = state;
      const data = {
        ...agencySettings.agencySettings,
        paymentGatewayList: action.payload.data.paymentGatewayList,
      };
      state.agencySettings = {
        agencySettings: {
          ...data,
        },
      };
      state.isPaymentGatewayLoading = false;
    },
    [updateAgencyPaymentGateway.rejected]: (state, action) => {
      state.isPaymentGatewayLoading = false;
      toast.error(action?.payload?.message);
    },

    [updateBitpayPaymentAllowed.pending]: (state) => {
      state.isBitpayPaymentLoading = true;
    },
    [updateBitpayPaymentAllowed.fulfilled]: (state, action) => {
      const { agencySettings } = state;
      const data = {
        ...agencySettings.agencySettings,
        isBitpayPaymentAllowed: action.payload.data.isBitpayPaymentAllowed,
      };
      state.agencySettings = {
        agencySettings: {
          ...data,
        },
      };
      state.isBitpayPaymentLoading = false;
    },
    [updateBitpayPaymentAllowed.rejected]: (state, action) => {
      state.isBitpayPaymentLoading = false;
      toast.error(action?.payload?.message);
    },

    [updateManageLocation.pending]: (state) => {
      state.isManageLocationLoading = true;
    },
    [updateManageLocation.fulfilled]: (state, action) => {
      const { agencySettings } = state;
      const data = {
        ...agencySettings.agencySettings,
        manageLocation: action.payload.data.manageLocation,
      };
      state.agencySettings = {
        agencySettings: {
          ...data,
        },
      };
      state.isManageLocationLoading = false;
    },
    [updateManageLocation.rejected]: (state, action) => {
      state.isManageLocationLoading = false;
      toast.error(action?.payload?.message);
    },

    [updateAgencyHotelVendor.pending]: (state) => {
      state.isHotelVendorLoading = true;
    },
    [updateAgencyHotelVendor.fulfilled]: (state, action) => {
      const { agencySettings } = state;
      const data = {
        ...agencySettings.agencySettings,
        hotelVendorsList: action.payload.data.hotelVendorsList,
      };
      state.agencySettings = {
        agencySettings: {
          ...data,
        },
      };
      state.isHotelVendorLoading = false;
    },
    [updateAgencyHotelVendor.rejected]: (state, action) => {
      state.isHotelVendorLoading = false;
      toast.error(action?.payload?.message);
    },

    // update agency OTP signup
    [updateOTPSignupStatus.pending]: (state) => {
      state.OTPSignupEnableLoading = true;
    },
    [updateOTPSignupStatus.fulfilled]: (state, action) => {
      const { agencySettings } = state;
      const data = {
        ...agencySettings?.agencySettings,
        OTPSignupEnable: action.payload.data.OTPSignupEnable,
      };
      state.agencySettings = {
        agencySettings: {
          ...data,
        },
      };
      state.OTPSignupEnableLoading = false;
    },
    [updateOTPSignupStatus.rejected]: (state, action) => {
      state.OTPSignupEnableLoading = false;
      toast.error(action?.payload?.message);
    },


    [updatHideHeaderStatus.pending]: (state) => {
      state.hideHeaderEnableLoading = true;
    },
    [updatHideHeaderStatus.fulfilled]: (state, action) => {
      const { agencySettings } = state;
      const data = {
        ...agencySettings.agencySettings,
        hideHeaderforDealProposal: action.payload.data.hideHeaderforDealProposal,
      };
      state.agencySettings = {
        agencySettings: {
          ...data,
        },
      };
      state.hideHeaderEnableLoading = false;
    },
    [updatHideHeaderStatus.rejected]: (state, action) => {
      state.hideHeaderEnableLoading = false;
      toast.error(action?.payload?.message);
    },

    [updateExranetStatus.pending]: (state) => {
      state.extranetLoading = true;
    },
    [updateExranetStatus.fulfilled]: (state, action) => {
      const { agencySettings } = state;
      const data = {
        ...agencySettings.agencySettings,
        extranetEnabled: action.payload.data.extranetEnabled,
      };
      state.agencySettings = {
        agencySettings: {
          ...data,
        },
      };
      state.extranetLoading = false;
    },
    [updateExranetStatus.rejected]: (state, action) => {
      state.extranetLoading = false;
      toast.error(action?.payload?.message);
    },

    [updateHideDealOrProposalOption.pending]: (state) => {
      state.hideDealOrProposalLoading = true;
    },
    [updateHideDealOrProposalOption.fulfilled]: (state, action) => {
      const { agencySettings } = state;
      const data = {
        ...agencySettings.agencySettings,
        hideDealOrProposalOption: action.payload.data.hideDealOrProposalOption,
      };
      state.agencySettings = {
        agencySettings: {
          ...data,
        },
      };
      state.hideDealOrProposalLoading = false;
    },
    [updateHideDealOrProposalOption.rejected]: (state, action) => {
      state.hideDealOrProposalLoading = false;
      toast.error(action?.payload?.message);
    },
  },
});

export default agencySlice.reducer;
